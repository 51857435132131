@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Book';
  font-style: normal;
  font-weight: 400;
  src: url(/fonts/book-antiqua.ttf);
  src: local('Book'),
  url(/fonts/book-antiqua.ttf) format('truetype'),
}

@font-face {
  font-family: 'Calibre';
  font-style: normal;
  font-weight: 400;
  src: url(/fonts/calibre/CalibreRegular.woff);
  src: local('Calibre'),
  url(/fonts/calibre/CalibreRegular.otf),
  url(/fonts/calibre/CalibreRegular.woff) format('woff'),
}
/*
@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
  body {
    color: white;
    background: black;
  }
} */

@layer components {
  #__next {
    @apply min-h-screen bg-lightgray
  }
  html,
  body {
    @apply font-body antialiased text-purple bg-white;
  }

  header a.active {
    @apply font-bold
  }

  .btn {
    @apply min-w-[160px] text-center bg-purple hover:bg-lightPurple py-2 px-10 text-white font-sans font-semibold rounded-lg text-lg drop-shadow-xl hover:drop-shadow-md
  }

  .btn-secondary {
    @apply min-w-[140px] text-center bg-purple hover:bg-lightPurple py-1.5 px-8 text-white font-sans font-semibold rounded-lg text-lg
  }

  .btn-orange {
    @apply bg-orange text-black
  }

  button:disabled.btn,
  button[disabled].btn{
    @apply drop-shadow-none bg-slate-500 opacity-40 hover:bg-slate-500 hover:drop-shadow-none
  }

  .inRow {
    @apply flex flex-row
  }
}


@layer utilities {

  h1 {
    @apply font-serif text-4xl text-purple
  }

  h2 {
    @apply font-serif text-3xl
  }

  h3 {
    @apply font-serif text-2xl
  }

  h4 {
    @apply font-serif text-lg
  }

  p.error-msg {
    @apply text-red-600
  }

  input.search-input {
    @apply pl-10 pt-0.5 h-[40px] text-lg w-full rounded-full bg-white px-2 text-black outline-none ring-1 ring-inset ring-purple
  }

  label {
    @apply font-semibold text-lg
  }

  input {
    @apply mb-4 pt-0.5 h-[40px] text-lg w-full rounded bg-white px-2 text-black outline-none ring-1 ring-inset ring-superFadedPurple
  }

  input.checkbox {
    @apply ring-0 outline-none border-none h-6 w-6
  }

  input.input-error {
    @apply ring-1 ring-red-600
  }

  form label {
    @apply font-semibold text-lg
  }

  form input {
    @apply mb-4 pt-0.5 h-[40px] text-lg w-full rounded bg-white px-2 text-black outline-none ring-1 ring-inset ring-purple
  }

  form .date-range input {
    @apply text-lg w-full rounded bg-white px-2 text-black outline-none ring-0 focus:ring-purple mb-0
  }

  form .date-range select {
    @apply text-lg w-full rounded bg-white px-2 text-black outline-none ring-0 focus:ring-purple mb-0
  }

  form .date-range .rdrDateDisplayItemActive {
    @apply border-[1px] border-fadedPurple shadow-none
  }

  form .date-range .rdrDefinedRangesWrapper {
    @apply text-lg
  }

  form .date-range .rdrDateDisplayWrapper {
    @apply bg-almostNoPurple
  }

  form .date-range .rdrMonthAndYearWrapper {
    @apply pt-0
  }

  form .date-range .rdrMonths {
    @apply text-base
  }
  form .date-range .rdrStartEdge {
    @apply rounded-l-full
  }
  form .date-range .rdrEndEdge {
    @apply rounded-r-full
  }
  form .date-range .rdrDayStartOfWeek .rdrInRange {
    @apply rounded-l-full
  }
  form .date-range .rdrDayEndOfWeek .rdrInRange {
    @apply rounded-r-full
  }
  form .date-range .rdrDayEndOfMonth .rdrInRange {
    @apply rounded-r-full
  }


  form textarea {
    @apply mb-4 pt-0.5 h-[120px] text-lg w-full rounded bg-white px-2 text-black outline-none ring-1 ring-inset ring-purple
  }

  form select {
    @apply mb-4 pt-0.5 h-[40px] text-lg w-full rounded bg-white px-2 text-black outline-none ring-1 ring-inset ring-purple
  }

  table {
    @apply table-fixed w-full text-lg
  }

  thead {
    @apply bg-superFadedPurple text-left
  }
  tbody {
    @apply text-black
  }
  tr {
    @apply even:bg-almostNoPurple
  }

  th {
    @apply py-2
  }

  th, td {
    @apply px-4 py-2
  }

  .show-page {
    @apply bg-white flex flex-col w-full max-w-7xl self-center p-12 h-full
  }

  .show-page .header{
    @apply bg-white flex flex-row gap-12 w-full max-w-7xl self-center py-8 h-full
  }

  .index-page {
    @apply bg-white flex flex-col items-center w-full max-w-7xl self-center p-12 h-full
  }

  .index-page .header {
    @apply w-full flex flex-row justify-between items-start mb-8
  }

  .index-page .content {

  }

  .rdt_TableBody {
    @apply text-lg
  }

}
